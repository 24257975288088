<template>
  <section class="footer bg-dark text-light text-center">
    <footer class="container p-2">DIRI Conference &copy;{{ new Date().getFullYear() }}</footer>
  </section>
</template>

<script>
export default {
  //name: "Footer"
};
</script>

<style scoped>
  
</style>