<template>
    <div class="container" >
        
        <div class="pt-5 pb-5">
            <h4> Download Call for Paper (English)<a href="/call for paper/cfp2023_1.jpeg" target="_blank" > page1 </a><a href="/call for paper/cfp2023_2.jpeg" target="_blank" > page2 </a></h4>
            <!-- <h4> Download Call for Paper (Bangla)<a href="/Call For Paper Bangla 2021.pdf" target="_blank" > Here </a></h4> -->
            <h4> Download MLA <a href="/MLA.pdf" target="_blank" > Here </a></h4>
        </div>
        <div class="row d-flex justify-content-center">
            <img class="img-fluid" src="/call for paper/cfp2023_1.jpeg">
            <img class="img-fluid" src="/call for paper/cfp2023_2.jpeg">
        </div>
       
    </div>
</template>
<script>
export default {
    data(){
        return{
            
        }
    },
    
}
</script>
<style lang="scss" scoped>
    
    table.table-bordered > tbody > tr > th{
         border:1px solid rgb(12, 12, 39);
    }
    
</style>