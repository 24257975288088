<template>
    <div class="container " >
        <div class="row">
        <div class="pb-5 col-md-12"><h3 class="text-center">International Conference Organizing Committee </h3></div>

        <div class="col-md-6 text-center" >
            <h4 class="col-md-12">Chief Patron  </h4>
            <hr class="color">
            <img src="/images/committee/Chief-Patron.jpg" class="img-fluid p-2" >
            <a class="text-blue" href="/syed-emdadul-hoque-aizbhandari" target="_blank">
                <h5>Syed Emdadul Hoque Maizbhandari</h5> 
            </a>
            <h6>Sajjadah Nashin,</h6>
            <h6>Maizbhandar Darbar Sharif & Founder, DIRI</h6>
        </div>
        <div class="col-md-6 text-center">
            <h4 class="col-md-12">Patron </h4>
            <hr class="color">
            <img src="/images/committee/Patron.jpg" class="img-fluid p-2" >
            <a class= " text-blue " href="/syed-irfanul-haque-maizbhandari" target="_blank"><h5>Syed Irfanul Hoque Maizbhandari </h5></a>
            <h6>Nayeb Sajjadah Nashin,</h6>
            <h6>Maizbhandar Darbar Sharif & Managing Trustee, DIRI</h6>
        </div>
        <div class="pt-5 col-md-12 text-center" >
            <h4>Convener </h4>
            <hr class="color">
            <div class="row">
                <div class="col-md-6">
                    <img src="/images/committee/Professor Dr. Obaidul Karim.png" class="img-fluid p-2 w-50">
                    <a class="text-blue" href="https://cu.ac.bd/public_profile/index.php?ein=2269" target="_blank"> <h5>Professor Dr. Obaidul Karim</h5></a>
                    <h6>Professor(Retd.) </h6>
                    <h6> Department of Sociology, University of Chittagong</h6>
                </div>
                <div class="col-md-6">
                    <img src="/images/committee/Professor Dr. Mohammad Jahangir Alam.png" class="img-fluid p-2 w-50">
                    <a class="text-blue" href="https://www.du.ac.bd/faculty/faculty_details/WRC/707" target="_blank"> <h5>Professor Dr. Mohammad Jahangir Alam</h5></a>
                    <h6>Chairman, Department of World Religions and Culture, University of Dhaka</h6>
                </div>
            </div>
        </div>
   
        <div class="pt-5 row col-md-12 text-center" >
            
            <h4 class="col-md-12">Co-Convener</h4>
            <hr class="col-md-12 color">
            <div class="col-md-4">
                
                <img src="/images/committee/Professor Dr. Md. Abu Sayem.png" class="img-fluid p-2 float-start">
                <a class="text-blue" href="https://du.ac.bd/faculty/faculty_details/WRC/705" target="_blank"> <h5>Professor Dr. Md. Abu Sayem</h5></a>
                <h6>Department of World Religions and Culture</h6>
                <h6>University of Dhaka</h6>
            </div>
            <div class="col-md-4">
                <img src="/images/committee/Dr. Joydeb Garai.png" class="img-fluid p-2" >
                <a class="text-blue" href="https://cu.ac.bd/public_profile/index.php?ein=5005" target="_blank"> <h5>Dr. Joydeb Garai</h5></a>
                <h6>Associate Professor</h6> 
                <h6>Department of Sociology, University of Chittagong</h6> 
            </div>
            <div class="col-md-4">
                <img src="/images/committee/Kazi Md. Siful Aspea.jpg" class="img-fluid p-2" > 
                <a class="text-blue" href=" https://www.ciu.edu.bd/our-teacher-details/SLASS%20Faculty%20Member%27s%20Detail/Faculty%20Member%27s%20Detail/our-teacher-details/slass/kazi-md-siful-aspea" target="_blank"> <h5>Kazi Md. Siful Aspea</h5></a>
                <h6>Head, Department of English,</h6> 
                <h6>Chittagong Independent University</h6> 
            </div> 
            
        </div>
        
        <div class="col-md-12 text-center pt-5" >
            <h4>Member Secretary</h4>
            <hr class="color">
            <img src="/images/committee/Dr. Shafi Md Mostafa.png" class="img-fluid p-2 w-25" style="float:left;">
            <a class= " text-blue " href="https://du.ac.bd/faculty/faculty_details/WRC/710" target="_blank"><h4>Dr. Shafi Md Mostafa </h4></a>
            <h6>Associate Professor, </h6> 
            <h6>Department of World Religions and Culture, University of Dhaka</h6>
        </div>
        
        <div class="pt-5 col-md-12 text-center row">
            <h4 class="col-md-12">Members</h4>
            <hr class="color col-md-12">
            <div class="col-md-3">
                <img src="/images/committee/Professor Dr. Amit Dey.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://research.caluniv.ac.in/researcher/amit-dey" target="_blank"><h4>Professor Dr. Amit Dey</h4></a>
                <h6>Asutosh Professor of Medieval and Modern Indian History</h6>
                <h6>University of Calcutta, India</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Professor Dr. Sirajul Islam.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://www.visvabharati.ac.in/MdSirajul_Islam.html" target="_blank"><h4>Professor Dr. Sirajul Islam</h4></a>
                <h6>Department of Philosophy & Comparative Religion</h6>
                <h6>Visva-Bharati University, India</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Professor Dr. K M Saiful Islam Khan.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://www.du.ac.bd/faculty/faculty_details/HFR/1210" target="_blank"><h4>Professor Dr. K M Saiful Islam Khan</h4></a>
                <h6>Department of Persian Language and Literature</h6>
                <h6>University of Dhaka</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Professor Dr. Asif Mahabub Karim.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://scholar.google.com/citations?user=F0-6lHkAAAAJ&hl=en" target="_blank"><h4>Professor Dr. Asif Mahabub Karim</h4></a>
                <h6>Founder and Honorary Chief Adviser</h6>
                <h6>Global Academics Research Academy (GARA), Malaysia</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Professor Dr. Mohammad Elius.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://du.ac.bd/faculty/faculty_details/WRC/706" target="_blank"><h4>Professor Dr. Mohammad Elius</h4></a>
                <h6>Department of World Religions and Culture</h6>
                <h6>University of Dhaka</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Professor Dr. Mohammad Moin Uddin.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://cu.ac.bd/public_profile/index.php?ein=4810" target="_blank"><h4>Professor Dr. Mohammad Moin Uddin</h4></a>
                <h6>Department of Law</h6>
                <h6>University of Chittagong</h6>
            </div>

            <div class="col-md-3">
                <img src="/images/committee/Dr. Md. Abdullah Al Mahmud.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://du.ac.bd/faculty/faculty_details/WRC/708" target="_blank"><h4>Dr. Md. Abdullah Al Mahmud</h4></a>
                <h6>Associate Professor,</h6>
                <h6>Department of World Religions and Culture, University of Dhaka</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Dr. Md. Shahinur Rahman.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://cu.ac.bd/public_profile/index.php?ein=4858" target="_blank"><h4>Dr. Md. Shahinur Rahman</h4></a>
                <h6>Associate Professor</h6>
                <h6>Department of Psychology, University of Chittagong</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Dr. Muhamamd Sheikh Sadi.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://cu.ac.bd/public_profile/index.php?ein=4970" target="_blank"><h4>Dr. Muhamamd Sheikh Sadi</h4></a>
                <h6>Associate Professor</h6>
                <h6>Department of Bangla, University of Chittagong</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Dr. Md. Mizanur Rahaman.png" class="img-fluid p-2" style="float:left;">
                 <a class= " text-blue " href="https://scholar.google.com/citations?hl=en&user=ifxm3hsAAAAJ&view_op=list_works&sortby=pubdate" target="_blank"><h4>Dr. Md. Mizanur Rahaman</h4></a>
                <h6>Associate Professor</h6>
                <h6>Qatar University, Qatar</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Dr. S. M. Masum Baki Billah.png" class="img-fluid p-2" style="float:left;">
                <a class= " text-blue " href="https://du.ac.bd/faculty/faculty_details/IST/1282" target="_blank"><h4>Dr. S. M. Masum Baki Billah</h4></a>
                <h6>Assistant Professor</h6>
                <h6>Department of Islamic Studies, University of Dhaka</h6>
            </div>
            <div class="col-md-3">
                <img src="/images/committee/Mohammad Shahed.png" class="img-fluid p-2" style="float:left;">
                <h5>Mohammad Shahed</h5>
                <h6>Researcher's Supervisor, State of Qatar</h6>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    hr.color {
        border-top: 1px solid rgb(70, 25, 7);
    }
</style>