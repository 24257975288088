<template>
    <nav class="navbar navbar-expand-lg ">
        <div class="d-flex justify-content-center container-fluid" >
            <button 
                class="navbar-toggler navbar-dark" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" 
                aria-expanded="false" 
                aria-label="Toggle navigation"
                >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarSupportedContent">
                
                <ul class="navbar-nav mx-auto "> 
                    <li class="nav-item ">
                        <router-link  to="/" class="nav-link border-right">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link  to="/callforpaper" class="nav-link border-right">Call For Paper</router-link>
                    </li>      
                    <li class="nav-item">
                        <router-link  to="/committee" class="nav-link border-right">Committee </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link  to="/schedule" class="nav-link border-right">Event Schedule</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link  to="/deadlines" class="nav-link border-right">Important Dates</router-link>
                    </li>         
                    <li class="nav-item">
                        <router-link  to="/registration" class="nav-link border-right">Registration</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link  to="/submit" class="nav-link border-right">Submit Paper</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link  to="/venue" class="nav-link border-right">Venue</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <router-link 
                            class="nav-link dropdown-toggle border-right" 
                            to ="" 
                            id="navbarDropdown" 
                            role="button" 
                            data-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false">
                            Previous Conference
                        </router-link>
                        <div class="dropdown-menu nav-item"  aria-labelledby="navbarDropdown">
                          <router-link  to="/conference-2022" class="nav-link">Conference 2022</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link  to="/conference-2021" class="nav-link">Conference 2021</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link  to="/conference-2020" class="nav-link">Conference 2020</router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link  to="/contact" class="nav-link border-right">Contact</router-link>
                    </li>
                </ul>
               
            </div>
        </div>
    </nav>
</template>

<script>

export default {
  name: 'Menu',
}
</script>


<style scoped>
  nav.navbar {
    background-color:rgb(27, 124, 119);
    font-size: 15px;
  }
  div.dropdown-menu {
    background-color: #8B072F;
  }
  nav >>> a {
    color: rgb(252, 250, 250);
  }
  nav >>> a:hover {
    background-color: #404040;
  }
  nav >>> a.dropdown-item {
    color: rgb(214, 188, 188);
    background-color: #ffffff;
  }
  nav >>> a.dropdown-item:hover {
    color: rgb(255, 254, 254);
    background-color: rgb(25, 12, 75);
  }
  .navbar-nav li:hover .dropdown-menu {
    display: block;
  }
  .navbar-nav>li>.dropdown-menu { min-width: 200px }

  
/* rotate caret on hover */
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
  } 
</style>
