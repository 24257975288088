<template>
    <div class="container" >
        <div class="pt-5 pb-5">
            <h4> Download Registration Form <a href="/Registration_Form_ICSSPHDQSH 2023.doc" target="_blank" > Here </a></h4>
            <h5>
                Please Submit Registration form to <a class= " text-primary" href="mailto: tasauf.darulirfan@gmail.com"> tasauf.darulirfan@gmail.com</a>
            </h5>
        </div>
        <!-- <div v-if="isSending" class="loading">Sendig...</div>
        <div class=" d-flex justify-content-center" >
            <form class="w-50 form" @submit.prevent="onSubmit">
                <div class="form-group">
                    <label for="Name">Name*</label>
                    <input type="text" class="form-control" id="Name" placeholder="Your Name" autocomplete="off" v-model="registration.name" required>
                </div>
                <div class="form-group">
                    <label for="Designation">Designation*</label>
                    <input type="text" class="form-control" id="Designation" placeholder="Your Designation" autocomplete="off" v-model="registration.designation" required>
                </div>
                <div class="form-group">
                    <label for="Organization">Organization*</label>
                    <input type="text" class="form-control" id="Organization" placeholder="Your Organization" autocomplete="off" v-model="registration.organization" required>
                </div>
                <div class="form-group">
                    <label for="Email">Email address*</label>
                    <input type="email" class="form-control" id="Email" placeholder="Enter email" autocomplete="off" v-model="registration.email" required>
                </div>
                <div class="form-group">
                    <label for="Phone">Phone No</label>
                    <input type="number" class="form-control" id="Phone" placeholder="Phone No" autocomplete="off" v-model="registration.phone">
                </div>
                <div class="form-group">
                    <label for="Address">Mailing Address*</label>
                    <textarea type="text" class="form-control" id="Address" placeholder="Mailing Address" autocomplete="off" v-model="registration.address" required></textarea>
                </div>
                <div class="form-group">
                    <label for="Type">Please Specify:* </label>
                    <select class="form-control" id="Type" v-model="registration.type" required >
                        <option value="Presenter">Presenter</option>
                        <option value="Audience">Audience</option>
                    </select>
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
            </form>
        </div> -->
    </div>
</template>

<script>

export default {
    name: "Registration",  
    data() {
        return{
            api_url: '/registration',
            registration: [],
            isSending: false
        }
    },
    created(){
        let registrationsub=[{
            name:'',
            designation:'',
            organization:'',
            email:'',
            phone:'',
            address:'',
            type:'',
        }]
        this.registration=registrationsub
    },
    methods: {
        async onSubmit() {
            this.isSending = true;
            const url = this.api_url;
            try {
                const newRegistration = {
                    name: this.registration.name,
                    designation: this.registration. designation,
                    organization: this.registration.organization,
                    email: this.registration.email,
                    phone: this.registration.phone,
                    address: this.registration.address,
                    type: this.registration.type,
                };
                console.log(newRegistration);
                const res = await this.$http.post(url, newRegistration);
                this.$awn.success('Created successfully');
                console.log(res);
                if(res){
                this.isSending = false;
                this.registration=[];
                }       
            }
            catch (err) {
                console.log(err);
            }			
        },
    },
}
</script>

<style lang="scss" scoped>
    
</style>