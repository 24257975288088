<template>
    <section >
        <defaultHeader  class="sticky-top"/>
        <slot class="container "/>
        <Footer />
    </section>   
</template>

<script>

    import defaultHeader from '@/components/Header';
    import Footer from '@/components/Footer';

    export default {
        name:'default_layout',
        components : {
            defaultHeader,
            Footer
        }
    }
</script>

<style scoped >
    
</style>