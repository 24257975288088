<template>
    <div class="container" >
        <div class="row pt-4">
            <div class="col-md-8 text-justify" >
                <div style="padding :20 0 30px 0;"> 
                    <Slide/>
                </div>
            </div>
            <div class="row col-md-4">
                <div class=" border-end text-center">
                    <img src='/ebook/Darul Irfan Research Journal.png' class="img-fluid p-2 w-50">
                    <a href="/ebook/Darul Irfan Research Journal.pdf" target="_blank">Download</a>
                </div>
                <div class=" border-end text-center">
                    <img src='/ebook/DIRI Conference Proceedings.png' class="img-fluid p-2 w-50">
                    <a href="/ebook/DIRI Conference Proceedings.pdf" target="_blank">Download</a>
                </div>
            </div>
            
        </div>
         
    </div>
</template>

<script>
import Search from '@/components/Search';
import Slide  from '@/components/Slide';
export default {
    name: "Home",
    components : {
        Search,
        Slide,
    },
    data(){
        return{
            
        }
    },
    methods : {
        search(keyword){
            this.items = data.filter(item => {
                return item.title.toLowerCase().indexof(keyword.toLowerCase()) !== -1
            })
        }
    }
}
</script>

<style scoped>
    img {
        max-width: 100%;
        max-height: 100%;
    }
</style>
