import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home'
import Callforpaper from '../views/Callforpaper'
import Committee from '../views/Committee'
import Speaker from '../views/Speaker'
import Schedule from '../views/Schedule'
import Deadlines from '../views/Deadlines'
import Registration from '../views/Registration'
import SubmitPaper from '../views/SubmitPaper'
import Venue from '../views/Venue'
import Conference2020 from '../views/Conference2020'
import Conference2021 from '../views/Conference2021'
import Conference2022 from '../views/Conference2022'
import Contact from '../views/Contact'
import SyedEmdadulHoqueMaizbhandari from '../views/SyedEmdadulHoqueMaizbhandari'
import SyedIrfanulHoqueMaizbhandari from '../views/SyedIrfanulHoqueMaizbhandari'

import Default from "@/layouts/Default"

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: Default }
  },
  
  {
    path: '/callforpaper',
    name: 'Callforpaper',
    component:Callforpaper,
    meta: { layout: Default }
  },
  {
    path: '/committee',
    name: 'Committee',
    component:Committee,
    meta: { layout: Default }
  },
  {
    path: '/speaker',
    name: 'Speaker',
    component:Speaker,
    meta: { layout: Default }
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component:Schedule,
    meta: { layout: Default }
  },
  {
    path: '/deadlines',
    name: 'Deadlines',
    component:Deadlines,
    meta: { layout: Default }
  },
  {
    path: '/registration',
    name: 'Registration',
    component:Registration,
    meta: { layout: Default }
  },
  {
    path: '/submit',
    name: 'SubmitPaper',
    component:SubmitPaper,
    meta: { layout: Default }
  },
  {
    path: '/venue',
    name: 'Venue',
    component:Venue,
    meta: { layout: Default }
  },
  {
    path: '/conference-2020',
    name: 'Conference 2020',
    component:Conference2020,
    meta: { layout: Default }
  },
  {
    path: '/conference-2021',
    name: 'Conference 2021',
    component:Conference2021,
    meta: { layout: Default }
  },
  {
    path: '/conference-2022',
    name: 'Conference 2022',
    component:Conference2022,
    meta: { layout: Default }
  },
  {
    path: '/contact',
    name: 'Contact',
    component:Contact,
    meta: { layout: Default }
  },  
  {
    path: '/syed-emdadul-hoque-aizbhandari',
    name: 'Syed Emdadul Hoque Maizbhandari',
    component:SyedEmdadulHoqueMaizbhandari,
    meta: { layout: Default }
  }, 
  {
    path: '/syed-irfanul-haque-maizbhandari',
    name: 'Syed Irfanul Hoque Maizbhandari',
    component:SyedIrfanulHoqueMaizbhandari,
    meta: { layout: Default }
  }, 
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async(to, from, next) => {  
  await Vue.nextTick()

  if(to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.adminAuth) ){
    if (!router.app.access_token) {   
      next({path : '/login', 
        query: { redirect: to.fullPath }
      })
    }
    else if (to.matched.some(record => record.meta.adminAuth)) {
      if(router.app.isAdmin) 
          next()
          else 
          next('/404')
    } else next()
    } 
    else {  
      if(to.path === '/login' && (router.app.access_token)) next('/')
      else next()
   }
  
});
export default router
