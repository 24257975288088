<template>
  <div >
    <nav class="navbar navbar-light">
      <form @submit.prevent="search" class="form-inline">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" v-model="keyword">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
      </form>
    </nav>
    
  </div>
</template>

<script>
export default {
  name: "defaultSearch",
  data(){
    return {
      keyword:''
    }
  },
  methods:{
    search(){
      this.$emit('search', this.keyword)
    }
  }
};
</script>