<template>
    <div class="container" >
        <div class="row justify-content-center ">
            <div class="col-auto pt-5">
                <table class="table table-bordered" >
                    <thead class="thead-dark">
                        <tr>
                        <th scope="col">Subject </th>
                        <th scope="col">Time</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <th scope="row">Abstract Due</th>
                            <th scope="row">15 June 2023</th>
                        </tr>
                        <tr>
                            <th scope="row">Abstract Acceptance </th>
                            <th scope="row">15 July 2023</th>
                        </tr>
                        <tr>
                            <th scope="row">Full Paper Submission Deadline </th>
                            <th scope="row">16 August 2023</th>
                        </tr>
                        <tr>
                            <th scope="row">Conference Date(Physical)</th>
                            <th scope="row">10 & 11 September 2023</th>
                        </tr>
                        <tr>
                            <th scope="row">e-Conference Date</th>
                            <th scope="row">22 & 23 September 2023</th>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    table.table-bordered > tbody > tr > th{
        border:1px solid rgb(12, 12, 39);
    }
</style>