<template>
    <div class="container text-center" >
        <h4>Will update soon </h4>
        <!-- <h4 class="text-center">
            30th October, 2020 <br/>
            Day- 1, Session- 1, <br/>
            Opening Session,
            Friday, 9:30am-12:40pm (GMT+6) 
        </h4>
        <h4 class="text-center">
            Day - 1, Session- 2, <br/>
            Friday, 3:00pm-6:15pm (GMT+6)
 
        </h4>
        <h4 class="text-center">
            31st October, 2020 <br/>
            Day - 2, Session - 1, <br/>
            Saturday, 9:30am-1:00pm (GMT+6)

        </h4>
        <h4 class="text-center">
            Day - 2, Session - 2,<br/>
             Closing Session, <br/>
            Saturday, 2:30pm-6:20pm (GMT+6)
        </h4> -->
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    
</style>