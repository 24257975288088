<template>
  <div id="app" > 
    <component :is="this.$route.meta.layout">
      <router-view class="container-fluid main-content mt-5" style="background-color:#E9E0CE"/>
    </component>
  </div>
</template>

<script>
  
  export default {
    name: 'App',
  };
</script>

<style lang="scss">
  @import'./assets/css/custom.scss';
  @import './assets/css/app.scss';
  .main-content {
  /* min-height: calc(100% - 50px); */
  min-height: calc(100vh - 96px); /* will cover the 100% of viewport */
  overflow: hidden;
  position: relative;
  padding-bottom: 100px; /* height of your footer */
  
}
</style>
