<template>
    <div class="container " > 
        <div class="pb-1 text-center">
            <h3>Short Introduction of  </h3>
        </div>
        <div class="pb-1 text-center">
            <h2>Syed Irfanul Hoque Maizbhandari </h2>
        </div>
        <div class="row pt-4">
            <div class="col-md-12">     
                <ul>
                    <li>Nayeb Sajjadah Nasheen, Gausia Ahmadia Monjil, Maizbhandar Darbar Sharif, Chattogram, Bangladesh.</li>
                    <li>Managing Trustee, Darul Irfan Research Institute (DIRI).</li>
                    <li>Vice President, Anjuman-e Muttabeyen-e Gause Maizbhandari (Shah Emdadia).</li>
                    <li>Chairman, Maizbhandari Foundation .</li>
                    <li>President, Maizbhandar Ahmadia Emdadia Madrasa.</li>
                    <li>President, Gausul Azam Maizbhandari Technical Institute (GAMTI).</li>
                    <li>President, Gausul Azam Maizbhandari Polytechnic Institute (GAMPI).</li>
                    <li>Patron, Maizbhandari Shah Emdadia Blood Donors Group.</li>
                    <li>Proprietor, Darul Irfan Enterprise.</li>
                    <li>Proprietor, Maizbhandari Agro Farm.</li>
                </ul>
            </div>  
        </div>
        <div class="pb-1 text-center">
            <h2>Author</h2>
        </div>
        <ul>
            <li>
                SOCIAL AWARENESS AND CREATIVITY ENHANCEMENT PROGRAM THROUGH E-LEARNING PLATFORM: A CASE OF MAIZBHANDER FOUNDATION'S MEDDHABIKASH PROGRAM, FATIKCHARI, BANGLADESH. DOI: 
                <a class="text-blue text-center" href="https://doi.org/10.46281/aesr.v7i1.1030" target="_blank">
                    <h5>https://doi.org/10.46281/aesr.v7i1.1030</h5> 
                </a>
            </li>
            <li>
                DELVING INTO BLOOD TRANSFUSIONS DATA THROUGH DATA MINING: A STUDY OF MAIZBHANDARI SHAH EMDADIA BLOOD DONORS GROUP TO SELECT VOLUNTEER BLOOD DONORS EFFICIENTLY. DOI: 
                <a class="text-blue text-center" href="https://doi.org/10.46281/aijmsr.v10i1.1308" target="_blank">
                    <h5>https://doi.org/10.46281/aijmsr.v10i1.1308</h5> 
                </a>
            </li>
            <li>
                APPLICATION OF SEVEN PRINCIPLES OF MAIZBHANDARI TARIQA ON BUSINESS ETHICS FOR SME’S SUSTAINABILITY IN FATIKCHARI, BANGLADESH. DOI: 
                <a class="text-blue text-center" href="https://doi.org/10.46545/aijbms.v3i1.206" target="_blank">
                    <h5>https://doi.org/10.46545/aijbms.v3i1.206</h5> 
                </a>
            </li>
            <li>
                CAN TAILORING SKILLS BE DELIVERED THROUGH E-LEARNING PLATFORM; PERSPECTIVE OF THE THREE-LEARNING DOMAINS (COGNITIVE, AFFECTIVE AND PSYCHOMOTOR DOMAIN). DOI: 
                <a class="text-blue text-center" href="https://doi.org/10.46545/aijelr.v4i1.290" target="_blank">
                    <h5>https://doi.org/10.46545/aijelr.v4i1.290</h5> 
                </a>
            </li>
            <li>
                EVALUATION OF PATIENTS’ SATISFACTION IN TELEMEDICINE SERVICE QUALITY: A CASE STUDY ON MAIZBHANDERI FOUNDATION, FATIKCHARI, BANGLADESH. DOI: 
                <a class="text-blue text-center" href="https://doi.org/10.46281/aesr.v8i1.1295" target="_blank">
                    <h5>https://doi.org/10.46281/aesr.v8i1.1295</h5> 
                </a>
            </li>
            <li>
                The Prospects and Challenges of Working from Home (WFH) in the New Norm: Case of DIRI, Chittagong, Bangladesh. Turkish Online Journal of Qualitative Inquiry (TOJQI)Volume 12, Issue 7, July 2021: 10527-10541. DOI: 
                <a class="text-blue text-center" href="http://tojqi.net/index.php/journal/index" target="_blank">
                    <h5>http://tojqi.net/index.php/journal/index</h5> 
                </a>
            </li>
            <li>
                ENROLLMENT READINESS AMONG THE PARENTS OF MADRASAH GOING CHILDREN WITH E-LEARNING FACILITIES IN MAIZBHANDAR AHMADIYYA EMDADIA MADRASAH, CHATTOGRAM, BANGLADESH. DOI: 
                <a class="text-blue text-center" href="https://doi.org/10.46545/aijelr.v4i1.293" target="_blank">
                    <h5>https://doi.org/10.46545/aijelr.v4i1.293</h5> 
                </a>
            </li>
            <li>
                A COMPARATIVE STUDY ON STUDENTS’ LEVEL OF ACCEPTANCE AND PREFERENCE OF CONVENTIONAL AND VIRTUAL MODE AT “GAUSUL AZAM MAIZBHANDERI MEDHA-BRITTHI (SCHOLARSHIP EXAM) PROGRAM IN FATIKCHARI, BANGLADESH. DOI: 
                <a class="text-blue text-center" href="https://doi.org/10.46281/aijssr.v6i1.1057" target="_blank">
                    <h5>https://doi.org/10.46281/aijssr.v6i1.1057</h5> 
                </a>
            </li>
        </ul>
         <div class="pb-1 text-center">
            <h2>Publisher</h2>
        </div>
        <ul>
                <li>Conference Proceedings of International e-Conference on “Sufism, Perfected Humanity and Divine Communion (ICSPHDC2020).”</li>
                <li>Conference Proceedings of International e-Conference on’’ sufi music perfected humanity and divine love (ICSMOHDL2021).</li>
            </ul>
        <div class="pb-1 text-center">
            <h2>Author</h2>
        </div>
         <ul>
            <li>Darul Irfan Research Journal (A journal on Sufism) ISSN:2710-3595/volume:1 Issue:1/2021 Darul Irfan Research Institute(DIRI)</li>
            <li>Usul-e Sab’a The Seven Methods of Self-Purification (Sharok Grontho) Isbn no- 978-984-34-8504-5</li>
            <li>Gayner Alo (Quaterly Magazine)</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "About"
}
</script>

<style lang="scss" scoped>

</style>