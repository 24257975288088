<template>
    <div class="container " >
        
        
        <div class="col-md-12 text-center">   
                <div class="row pt-3 text-center">
                    <div class="col-md-4">
                        <img src="/images//mobile.png" class="mx-auto w-25">
                        <h6>+8801861-666111</h6>
                    </div>
                    <div class="col-md-4">
                        <img src="/images//mail.png" class="mx-auto w-25"> <br>
                        <a  href="mailto: tasauf.darulirfan@gmail.com"> tasauf.darulirfan@gmail.com </a> <br>
                        <a href="mailto:admin@diri.org.bd">admin@diri.org.bd</a>

                    </div>
                    <div class="col-md-4">
                        <img src="/images//address.png" class="mx-auto w-25">
                        <h6>House: 6/G, Road #04 </h6> 
                        <h6>Zakir Hossain Society,South Khulshi</h6> 
                        <h6>Chattogram, Bangladesh</h6> 
                        <h6>Post Code: 4225</h6>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    
</style>