<template>
    <!--Carousel Wrapper-->
    <div id="carousel-example-2" class="carousel slide carousel-fade" data-ride="carousel">
        <!--Indicators-->
        <ol class="carousel-indicators">
            <li data-target="#carousel-example-2" data-slide-to="0" class="active"></li>
            <li data-target="#carousel-example-2" data-slide-to="1"></li>
            <li data-target="#carousel-example-2" data-slide-to="2"></li>
            
            
        </ol>
        <!--/.Indicators-->
        <!--Slides-->
        <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
                <div class="view">
                    <img class="img-fluid d-block  w-100"  src='/images/slides/diri_conference_2023.jpg'
                    alt="First slide">
                    <div class="mask rgba-black-light"></div>
                </div>
                
            </div>
            <div class="carousel-item">
                <!--Mask color-->
                <div class="view">
                    <img class="img-fluid d-block w-100 mx-auto " src='/images/slides/diri_conference_2023.jpg'
                    alt="Second slide">
                    <div class="mask rgba-black-strong"></div>
                </div>
                
            </div>
            <div class="carousel-item">
                <!--Mask color-->
                <div class="view">
                    <img class="img-fluid d-block w-100 mx-auto" src='/images/slides/diri_conference_2023.jpg'
                    alt="Third slide">
                    <div class="mask rgba-black-slight"></div>
                </div>
                
            </div>
            
                
        
        </div>
        <!--/.Slides-->
        <!--Controls-->
        <a class="carousel-control-prev" href="#carousel-example-2" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carousel-example-2" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
        <!--/.Controls-->
    </div>
    <!--/.Carousel Wrapper-->
</template>

<script>
export default {
    name:'slide',
    data(){
        return {
           
            
        }
    },
    methods:{
        openwindow(){
            window.location.href = 'https://conference.diri.org.bd';
        }
    }
}
</script>
<style scoped>

</style>