<template>
    <div class="container" >
        <h3 class="text-center text-primary"> September 10-11, 2023 </h3> 
        <h3 class="text-center">Senate Bhaban , University of Dhaka, Dhaka, Bangladesh &</h3> <br><br><br>
        <h3 class="text-center text-primary">September 15-16, 2023 </h3>
        <h3 class="text-center">e-Conference in Zoom Webinar Platform</h3> 
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    
</style>