import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';

import jQuery from 'jquery';
import 'popper.js';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/app.scss';

import router from './router/router';
import store from './store/store';

import VueAWN from "vue-awesome-notifications"
Vue.use(VueAWN, {
  position: 'top-right',
  durations: {
    global: 2000,
  }
});

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL ='//localhost:8000/api/';
const token = localStorage.getItem('access-token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.prototype.$jQuery = jQuery;
Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App),
  data() {
    return {
      access_token: localStorage.getItem('access-token') || null,
      isAdmin: JSON.parse(localStorage.getItem('isAdmin')) || false,
    }
  }
}).$mount('#app')
