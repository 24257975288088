<template>
    <div class="container" > 
        <div class="pb-1" style="text-align: center;">
            <h3>Short Introduction of  </h3>
        </div>
        <div class="pb-1" style="text-align: center;">
            <h2>Syed Emdadul Hoque Maizbhandari </h2>
        </div>
        <div class="row pt-4">
            <div class="col-md-12" style="text-align:justify">     
                <p>Syed Emdadul Hoque Maizbhandari is the Sajjadah Nasheen (Existing Pir) of 
                Maizbhandar Darbar Sharif, Chattogram, Bangladesh. This Maizbhandar Darbar 
                Sharif was founded by Gausl Azam Syed Ahmad Ullah Maizbhandari who initiated 
                Maizbhandari Tariqa. As a spiritual leader Syed Emdadul Hoque Maizbhandari is 
                inspiring millions of people by preaching Usul-e Sab’a or the Seven Step Methods 
                of Maizbhandari Tariqa to achieve the humane conscience through Tazkiah or self-purification. 
                He is not only working in the spiritual arena of Sufism but also working in the practical 
                arena of education, research and publications, health and environment, disaster management, 
                self-reliance and social awareness. For conducting these multilateral activities he has 
                founded and manages various organizations.</p>
                <p>For spreading and ensuring quality education he has founded Maizbhandar Ahmadia Emdadia 
                Madrasha, Gausul Azam Maizbhandari Technical Institute, Gausul Azam Maizbhandari Hifz Khana, 
                Maizbhandari Foundation etc. Through these organizations he continuously serving the nation for 
                spreading and achieving quality education. He has founded Darul Irfan Research Institute (DIRI) 
                for the academic research on Sufism. DIRI organizes Conferences, seminars and regular talks on Sufism. 
                Through these educational and research institutes he has initiated many scholarships and award 
                for the students and academicians. He has also founded Maizbhandari Prokashoni for the publications 
                of books, journals, and periodicals on Islam and Sufism.</p>  
            
                <p>Serving the nation in the health sector, he has founded Maizbhandari Shah Emdadia 
                Blood Donors Group. This organization manages blood donation, tele-medicine services
                and specialized medical camps for the poor and under privileged people of the society.
                He has initiated the program of planting herbal medicine plants in some specific villages
                as Oushudi Gram from where people are getting the herbal remedy of trivial diseases.
                He has also initiated tree plantation program for preserving the natural environment.</p>
                <p>As Bangladesh is the prey of regular natural disasters he is very keen on disaster
                management issues such as rescues and rehabilitations, relief, financial aid and donations 
                for the effected people. He has also initiated Shah Emdadia Shawnirborota Uddoyg for the poor 
                but potential people. By this program CNG auto rickshaw, sewing machine, laptop and others 
                agricultural and technical tools are given to the trained underprivileged people for being self-reliant.</p>
                <p>For creating awareness in the society on various issues he has initiated various campaign programs. 
                At present some campaign programs are running to aware people about the abuse of technology, 
                using GM food, the care of senior citizens, and counselling on mental health issues.</p>
                <p>Syed Emdadul Hoque Maizbhandari thus serving the nation and the muslim ummah by his
                spiritual leadership and the worldly activities of practical life.</p>
            </div>  
        </div>
    </div>
</template>

<script>
export default {
    name: "About"
}
</script>

<style lang="scss" scoped>

</style>