<template>
 <section style="background-color: #1BB7A3 "> 
    <section class="col-md-12 row" >
      <section class="col-md-2 text-center" style="cursor:pointer">
        <img src="/images/logo.png" class="img-fluid w-50" v-on:click="openwindow">
      </section>
      <section class="col-md-10 mx-auto row">
          <img src="/images/name.jpeg" class="w-100 img-fluid float-left" style="height:70px">
          <Menu class="w-100"/> 
      </section>
    </section>
 </section>
</template>

<script>
import Menu from "@/components/Menu";
export default {
  name: "defaultHeader",
  data(){
    return{
      
    }
  },
  components: {
    Menu,
  },
  methods:{
        openwindow(link){
            link = 'https://diri.org.bd';
            window.open(link,"_blank");
        }
    },

};
</script>