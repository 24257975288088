<template>
    <div class="container " >
        <div class="text-center"> 
            <h4>Will update soon </h4>
        </div>  
    </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
}
</script>

<style lang="scss" scoped>
    
</style>