<template>
    <div class="container" >
        <div class="pt-5 pb-5">
            
            <h5>
                Please Submit Paper to <a class= " text-primary" href="mailto: tasauf.darulirfan@gmail.com"> tasauf.darulirfan@gmail.com</a>
            </h5>
        </div>
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    
</style>